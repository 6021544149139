import React, { useState, useEffect } from "react";
import { Table, Alert, Card, DatePicker, Row, Col, Select } from "antd";
import { fetchCurrentStock, loadInvoiceProducts, fetchCategories } from "../services/ApiServices/apiFunctions";
import SearchableSelect from "../components/common/SearchableSelect";
import dayjs from "dayjs";

const { Option } = Select;
interface DataItem {
  SrNo: number
  ProductName: string;
  Unit: string;
  Qty: number;
  PurchaseRate: number;
  Amount: number;
}

interface ProductsList {
  ID: number;
  BrandName: string;
}

interface Category {
  id: number;
  CategoryName: string;
}

const CurrentStock: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [productsList, setProductsList] = useState<ProductsList[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedProductID, setSelectedProductID] = useState<number>(0);
  const [selectedCategoryID, setSelectedCategoryID] = useState<number>(0);
  const [selectedUnit, setSelectedUnit] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [productType, setProductType] = useState<string>('');
  const [grandTotal, setGrandTotal] = useState<number>(0);
  const [totalQty, setTotalQty] = useState<number>(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    loadProducts();
    loadCategories();
  }, []);

  useEffect(() => {
    fetchTableData(
      pagination.current,
      pagination.pageSize,
      selectedDate ? dayjs(selectedDate).format("YYYY-MM-DD") : null,
      selectedProductID,
      selectedCategoryID,
      selectedUnit,
      productType
    );
  }, [pagination.current, pagination.pageSize, selectedDate, selectedProductID, selectedCategoryID, selectedUnit, productType]);

  const loadProducts = async () => {
    setLoading(true);
    try {
      const data = await loadInvoiceProducts();
      if (Array.isArray(data.responseObject)) {
        const allOption = { ID: 0, BrandName: "ALL" };
        setProductsList([allOption, ...data.responseObject]);
        setError(null);
      } else {
        setError("Error: Invalid data format");
      }
    } catch (error) {
      setError("Error fetching products. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const loadCategories = async () => {
    try {
      const data = await fetchCategories();
      if (Array.isArray(data.responseObject)) {
        const allOption = { id: 0, CategoryName: "ALL" };
        setCategories([allOption, ...data.responseObject]);
      } else {
        setError("Error: Invalid category data format");
      }
    } catch (error) {
      setError("Error fetching categories. Please try again later.");
    }
  };

  const fetchTableData = async (
    page: number,
    pageSize: number,
    toDate: string | null,
    ProductID: number,
    CategoryID: number,
    Unit: string,
    productType: string
  ) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchCurrentStock(page, pageSize, toDate, ProductID, CategoryID, Unit, productType);
      setGrandTotal(response.responseObject.grandTotal);
      setTotalQty(response.responseObject.totalQty);
      setData(response.responseObject.currentStock);
      setPagination({
        ...pagination,
        total: response.responseObject.pagination.total,
      });
    } catch (error) {
      setError("An error occurred while fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination: any) => {
    setPagination(pagination);
  };

  const handleProductChange = (value: number) => {
    setSelectedProductID(value);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handleCategoryChange = (value: number) => {
    setSelectedCategoryID(value);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handleUnitChange = (value: string) => {
    setSelectedUnit(value);
    setPagination({
      ...pagination,
      current: 1,
    });
  };

  const handleDateChange = (date: any) => {
    setSelectedDate(date ?? null);
    setPagination({
      ...pagination,
      current: 1,
    });
  };
  const handleProductType = (value: any) => {
    setProductType(value ?? '');
    setPagination({
      ...pagination,
      current: 1,
    });
  };
  const columns = [
    {
      title: "Sr.No",
      key: "serialNo",
      align: "center" as "center",
      render: (text: any, record: DataItem, index: number) => index + 1,
    },
    {
      title: "Product Name",
      dataIndex: "ProductName",
      key: "ProductName",
    },
    {
      title: "Unit",
      dataIndex: "Unit",
      key: "Unit",
    },
    {
      title: "Qty",
      dataIndex: "Qty",
      key: "Qty",
      render: (text: string) => <div style={{ textAlign: "right" }}>{text}</div>,
    },
    {
      title: "Rate",
      dataIndex: "PurchaseRate",
      key: "PurchaseRate",
      render: (text: string) => <div style={{ textAlign: "right" }}>{text}</div>,
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      render: (text: string) => <div style={{ textAlign: "right" }}>{text}</div>,
    },
  ];

  return (
    <Card title="Current Stock">
      {error && (
        <Alert
          message={error}
          type="error"
          closable
          style={{ marginBottom: "10px" }}
        />
      )}
      <Row gutter={[16, 16]}>
        {/* Product Selection */}
        <Col xs={24} sm={12} md={8}>
          <label htmlFor="product-select">Product</label>
          <SearchableSelect
            options={productsList}
            placeholder="Select a Product"
            onChange={handleProductChange}
            idKey="ID"
            nameKey="BrandName"
          />
        </Col>

        {/* Category Selection */}
        <Col xs={24} sm={12} md={4}>
          <label htmlFor="category-select">Category</label>
          <SearchableSelect
            options={categories}
            placeholder="Select a Category"
            onChange={handleCategoryChange}
            idKey="id"
            nameKey="CategoryName"
          />

        </Col>

        {/* Unit Selection */}
        <Col xs={24} sm={12} md={4}>
          <label htmlFor="unit-select">Unit</label>
          <Select
            placeholder="Select Unit"
            onChange={handleUnitChange}
            style={{ width: '100%' }}
            value={selectedUnit} // to display the selected value
          >
            <Option value="">ALL</Option>
            <Option value="Meter">METER</Option>
            <Option value="Piece">PIECE</Option>
          </Select>
        </Col>
        <Col xs={24} sm={12} md={4}>
          <label htmlFor="product-type">Product Type</label>
          <Select
            placeholder="Product Type"
            onChange={handleProductType}
            style={{ width: '100%' }}
            value={productType} // to display the selected value
          >
            <Option value="">ALL</Option>
            <Option value="NEW">NEW</Option>
            <Option value="USED">USED</Option>
          </Select>

        </Col>
        {/* Till Date Picker */}
        <Col xs={24} sm={12} md={4}>
          <label htmlFor="till-date-picker">Till Date</label>
          <DatePicker
            defaultValue={selectedDate}
            onChange={handleDateChange}
            style={{ width: '100%' }}
            placeholder="Select Date"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={pagination}
            onChange={handleTableChange}
            rowKey="ProductID"
            size="small"
            scroll={{ x: true }}
            bordered
            summary={() => (
              <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={2}></Table.Summary.Cell>
                <Table.Summary.Cell index={2} align="right">
                  <strong>Grand Total</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={3} align="right">
                  <strong>{totalQty}</strong>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={4} align="right">
                </Table.Summary.Cell>
                <Table.Summary.Cell index={5} align="right">
                  <strong>{grandTotal}</strong>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default CurrentStock;
